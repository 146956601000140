<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#C7CFE2;"
      d="M482.935,238.684H379.039l-21.373,121.212h131.264c4.201,0,7.797-3.016,8.526-7.155l14.004-79.425
	C514.833,254.189,502.062,238.684,482.935,238.684z"
    />
    <path
      style="fill:#AFB9D2;"
      d="M375.986,256h103.896c9.564,0,15.949,7.752,14.263,17.316l-15.266,86.58H357.667L375.986,256z"
    />
    <path
      style="fill:#959CB5;"
      d="M478.189,359.896c0.965-29.359-20.234-51.948-50.758-51.948s-59.689,22.589-69.077,51.948H478.189z"
    />
    <path
      style="fill:#C7CFE2;"
      d="M466.622,134.788h-69.264l-6.106,34.632h91.606c2.101,0,3.898-1.509,4.263-3.577l0.896-5.081
	C490.546,146.417,480.967,134.788,466.622,134.788z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M460.036,368.554c-4.215,23.908-27.015,43.29-50.923,43.29s-39.872-19.382-35.657-43.29
	c4.215-23.908,27.015-43.29,50.923-43.29S464.251,344.645,460.036,368.554z"
    />
    <polygon
      style="fill:#B4E6FF;"
      points="482.935,238.684 477.832,169.42 391.252,169.42 379.039,238.684 "
    />
    <path
      style="fill:#C7CFE2;"
      d="M442.72,368.554c-2.529,14.345-16.209,25.974-30.554,25.974s-23.923-11.629-21.394-25.974
	c2.529-14.345,16.209-25.974,30.554-25.974C435.67,342.58,445.249,354.209,442.72,368.554z"
    />
    <path
      style="fill:#7F8499;"
      d="M425.404,368.554c-0.843,4.781-5.403,8.658-10.185,8.658c-4.783,0-7.974-3.877-7.131-8.658
	c0.843-4.781,5.403-8.658,10.185-8.658C423.054,359.896,426.247,363.773,425.404,368.554z"
    />
    <path
      style="fill:#9BD6FF;"
      d="M422.831,186.736h56.277l-1.276-17.316h-86.58l-12.213,69.264h25.974l7.633-43.29
	C413.489,190.613,418.049,186.736,422.831,186.736z"
    />
    <path
      style="fill:#AFB9D2;"
      d="M394.305,152.104h69.264c4.781,0,7.974,3.877,7.131,8.658l-1.527,8.658h-77.922L394.305,152.104z"
    />
    <polygon
      style="fill:#F1F4FB;"
      points="485.487,273.316 511.461,273.316 505.354,307.948 479.38,307.948 "
    />
    <path
      style="fill:#FFC850;"
      d="M468.171,273.316l-3.053,17.316c-1.686,9.563,4.699,17.316,14.263,17.316h8.658l6.106-34.632H468.171
	z"
    />
    <path
      style="fill:#FF507D;"
      d="M394.807,100.156H100.435c-4.781,0-9.342,3.877-10.185,8.658l-30.532,173.16H33.743
	c-4.786,0-9.342,3.877-10.185,8.658c-0.843,4.781,2.346,8.658,7.131,8.658h60.606c4.786,0,7.974,3.877,7.131,8.658
	c-0.843,4.781-5.399,8.658-10.185,8.658H53.609l-6.106,34.632c-0.843,4.781,2.35,8.658,7.131,8.658h303.03l44.273-251.082
	C402.781,104.031,399.589,100.156,394.807,100.156z"
    />
    <path
      style="fill:#D23C69;"
      d="M188.412,316.606c-8.111-5.414-18.048-8.658-29.379-8.658c-11.331,0-22.411,3.244-32.432,8.658H10.32
	c-4.786,0-9.342,3.877-10.185,8.658c-0.843,4.781,2.346,8.658,7.131,8.658h43.29l-3.053,17.316c-0.843,4.781,2.35,8.658,7.131,8.658
	h303.03l7.633-43.29H188.412z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M191.637,368.554c-4.215,23.908-27.015,43.29-50.923,43.29s-39.872-19.382-35.657-43.29
	c4.215-23.908,27.015-43.29,50.923-43.29S195.853,344.645,191.637,368.554z"
    />
    <path
      style="fill:#C7CFE2;"
      d="M174.321,368.554c-2.529,14.345-16.209,25.974-30.554,25.974s-23.923-11.629-21.394-25.974
	c2.529-14.345,16.209-25.974,30.554-25.974C167.273,342.58,176.85,354.209,174.321,368.554z"
    />
    <path
      style="fill:#7F8499;"
      d="M157.005,368.554c-0.843,4.781-5.403,8.658-10.185,8.658c-4.781,0-7.974-3.877-7.131-8.658
	c0.843-4.781,5.403-8.658,10.185-8.658S157.848,363.773,157.005,368.554z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M339.108,249.075l-30.052-25.974c-3.318-2.858-8.88-2.097-12.422,1.734
	c-3.549,3.821-3.728,9.25-0.403,12.116l12.018,10.392H106.603c-4.786,0-9.341,3.872-10.185,8.658
	c-0.844,4.786,2.346,8.658,7.131,8.658h201.647l-15.683,10.392c-4.336,2.866-6.07,8.294-3.87,12.116
	c1.299,2.275,3.684,3.466,6.321,3.466c1.81,0,3.727-0.566,5.488-1.734l39.212-25.974c2.469-1.632,4.208-4.202,4.687-6.924
	S341.002,250.707,339.108,249.075z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
